import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField,
  Tooltip
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { funcValidation } from 'src/utils/validation';
import {
  LIMIT_TOPIC_ANSWERS_ERROR,
  UNIQUE_TOPIC_ANSWERS_ERROR
} from 'src/utils/constants/stringConstants';
import { ANSWER_MAX_LENGTH } from 'src/utils/constants/numericConstants';
import useStyles from 'src/assets/scss/modalWindow';
import EditableList from '../../../../components/EditableList';
import { numberOfTopicAnswers } from '../constants';
import operationsContext from '../../context/operations.context';
import { removeExtraSpaceFromObjectValues } from '../../../../utils/common';
import { getCategoryProducts, getDealTypesForCategory, prepareParams } from '../utils';

const defaultState = {
  values: {
    topicName: '',
    categoryId: '',
    question: '',
    tooltip: '',
    defaultAnswers: []
  },
  touched: {},
  errors: {}
};

function AddTopicModal({ open, onClose, className, submit, checkUniqueTopicName }) {
  const classes = useStyles();
  const {
    state: { categories, groupedByProductDealTypes }
  } = useContext(operationsContext);
  const [formState, setFormState] = useState(defaultState);

  useEffect(() => {
    setFormState(defaultState);
  }, [open]);

  const validationFieldSettings = useCallback(
    () => ({
      topicName: {
        required: 'topicName',
        unique: ['topicName', (name) => checkUniqueTopicName(name)]
      },
      categoryId: {
        required: 'categoryId'
      }
    }),
    [checkUniqueTopicName]
  );

  const dealTypes = getDealTypesForCategory(
    groupedByProductDealTypes.data,
    getCategoryProducts(categories.data, formState.values.categoryId)
  );

  useEffect(() => {
    if (open) {
      const errors = {};
      const validationFields = validationFieldSettings();
      Object.keys(validationFields).forEach((fieldName) => {
        const formStateValues = formState.values[fieldName] ? `${formState.values[fieldName]}` : '';
        errors[fieldName] = funcValidation(validationFields[fieldName], formStateValues);
      });

      setFormState((prevFormState) => ({
        ...prevFormState,
        errors
      }));
    }
  }, [open, formState.values, validationFieldSettings]);

  const hasError = (fieldName) => !!formState.errors[fieldName] && !!formState.touched[fieldName];

  const getErrorText = (fieldName) => (hasError(fieldName) ? formState.errors[fieldName] : '');

  const handleChange = (event, id = null) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event?.target?.name || 'categoryId']:
          event.target.type === 'checkbox' ? event.target.checked : id || event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event?.target?.name || 'categoryId']: true
      }
    }));
  };

  const hasSomeError = Object.keys(formState.errors).some((key) => formState.errors[key]);

  const handleAnswersChanged = (changedList) => {
    setFormState((prevForm) => ({
      ...prevForm,
      values: {
        ...prevForm.values,
        defaultAnswers: changedList.map((item) => ({
          answer: item.answer,
          position: item.position,
          defaultAnswerScores: item.defaultAnswerScores
        }))
      }
    }));
  };

  const save = () => {
    const isChanged = false;
    const params = removeExtraSpaceFromObjectValues(formState.values);
    submit(prepareParams(params));
    onClose(isChanged);
  };

  const onCloseHandler = () => {
    const isChanged = Object.keys(formState.touched).length > 0;
    onClose(isChanged);
  };

  if (!open) {
    return null;
  }

  const headerModal = (
    <div className={classes.headerModal}>
      <span>Add New Topic</span>
      <CloseIcon className={classes.cross} onClick={onCloseHandler} />
    </div>
  );

  return (
    <Modal onClose={onCloseHandler} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent>
          <Typography variant="body1" component="div">
            <TextField
              error={hasError('topicName')}
              fullWidth
              helperText={getErrorText('topicName')}
              label="Topic Name *"
              margin="normal"
              name="topicName"
              onChange={handleChange}
              value={formState.values.topicName || ''}
              variant="outlined"
              inputProps={{
                maxLength: 500
              }}
            />
            <Autocomplete
              autoHighlight
              size="medium"
              fullWidth
              options={categories.data}
              getOptionLabel={(option) => option.name}
              onChange={(event, option) => handleChange(event, option?.id)}
              renderOption={(props, option) => (
                <li {...props}>
                  <Tooltip
                    disableInteractive
                    title={`Products: ${option.products.map((product) => product.name).join(', ')}`}
                    placement="top"
                  >
                    <span>{option.name}</span>
                  </Tooltip>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  name="categoryId"
                  error={hasError('categoryId')}
                  helperText={getErrorText('categoryId')}
                  label="Topic Category *"
                  variant="outlined"
                  value={formState.values.categoryId || ''}
                />
              )}
            />
            <TextField
              fullWidth
              label="Question"
              margin="normal"
              name="question"
              onChange={handleChange}
              value={formState.values.question || ''}
              variant="outlined"
              inputProps={{
                maxLength: 500
              }}
            />
            <EditableList
              initialList={formState.values.defaultAnswers}
              dealTypes={dealTypes}
              categoryId={formState.values.categoryId}
              onListChange={handleAnswersChanged}
              sizeLimit={numberOfTopicAnswers}
              maxItemLength={ANSWER_MAX_LENGTH}
              errorOnLimit={LIMIT_TOPIC_ANSWERS_ERROR}
              errorOnUnique={UNIQUE_TOPIC_ANSWERS_ERROR}
            />
            <TextField
              fullWidth
              label="Tooltip"
              margin="normal"
              name="tooltip"
              onChange={handleChange}
              value={formState.values.tooltip || ''}
              variant="outlined"
              inputProps={{
                maxLength: 500
              }}
            />
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button className={classes.closeButton} onClick={onCloseHandler}>
            CLOSE
          </Button>
          <Button disabled={hasSomeError} color="primary" variant="contained" onClick={save}>
            SAVE
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

AddTopicModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  submit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  checkUniqueTopicName: PropTypes.func
};

AddTopicModal.defaultProps = {
  open: false
};

export default AddTopicModal;

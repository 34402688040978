import React from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'swiper/css/bundle';

LicenseInfo.setLicenseKey(
  '6c75eb827ca748c94c6afc6a545f9db3Tz03NTg1MyxFPTE3Mjc5NDQ4NjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const root = createRoot(document.getElementById('root'));

root.render(<App />);

serviceWorker.unregister();

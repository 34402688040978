import React from 'react';
import { Navigate } from 'react-router-dom';
import ComparisonLayout from './MainLayout/DealPage/TabPages/Comparison/ComparisonLayout';
import DealPageTabsLayout from './MainLayout/DealPage/TabPages';
import MainLayout from './MainLayout';
import OperationsPage from './MainLayout/OperationsPage';
import CloRedirect from './components/CloRedirect';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import { AboutUsPage } from './MainLayout/AboutUsPage';
import {
  AnalyticsManagement,
  CategoryManagement,
  DealPage,
  DealPageDetails,
  DealTypeManagement,
  Error401,
  Error403,
  Error404,
  Error500,
  ForgotPasswordForm,
  HomePage,
  Login,
  PDFLayout,
  TrackChanges,
  Portfolios,
  PrivateContent,
  ProductManagement,
  ReportViewPage,
  ReportsPage,
  TopicManagement,
  Topics,
  UserManagement,
  Workflow,
  SignUp
} from './lazyRoutes';

const routes = [
  {
    path: '/auth/*',
    element: <AuthLayout />,
    errorElement: <Error404 />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'forgot',
        element: <ForgotPasswordForm />
      },
      {
        path: 'sign-up',
        element: <SignUp />
      }
    ]
  },
  {
    path: 'errors/*',
    element: <ErrorLayout />,
    children: [
      {
        element: <Error404 />
      },
      {
        path: 'error-401',
        element: <Error401 />
      },
      {
        path: 'error-404',
        element: <Error404 />
      },
      {
        path: 'error-500',
        element: <Error500 />
      },
      {
        path: 'error-403',
        element: <Error403 />
      }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <Error404 />,
    children: [
      {
        path: '',
        element: <Navigate to="deal/page/deals" />
      },
      {
        path: 'deal/page/',
        element: <DealPageTabsLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="deals" />
          },
          {
            path: 'deals',
            element: <DealPage />
          },
          {
            path: 'track-changes',
            element: <TrackChanges />
          },
          {
            path: 'portfolios',
            element: <Portfolios />
          },
          {
            path: 'topics',
            element: <Topics />
          },
          {
            path: 'comparison',
            element: <ComparisonLayout />
          },
          {
            path: 'comparison/:portfolioId',
            element: <ComparisonLayout />
          },
          {
            component: () => <Navigate to="/deal/page/deals" />
          }
        ]
      },
      {
        path: 'clo/*',
        element: <CloRedirect />
      },
      {
        path: 'clo-pdf/*',
        element: <CloRedirect />
      },
      {
        path: 'pdf/clo/*',
        element: <CloRedirect />
      },
      {
        path: 'deal/:dealId',
        element: <DealPageDetails />
      },
      {
        path: 'deal/:dealId/document/:documentId',
        element: <DealPageDetails />
      },
      {
        path: 'deal-pdf/:dealId',
        element: <PDFLayout />
      },
      {
        path: 'pdf/deal/:dealId/document-pdf/:documentId',
        element: <PDFLayout />
      },
      {
        path: 'about-us',
        element: <AboutUsPage />
      },
      {
        path: 'home',
        element: <HomePage />
      },
      {
        path: 'reports/',
        element: <ReportsPage />
      },
      {
        path: 'reports/:reportId',
        element: <ReportViewPage />
      },
      {
        path: 'operations',
        element: <OperationsPage />,
        children: [
          {
            path: 'user-management',
            element: <UserManagement />
          },
          {
            path: 'topic-management',
            element: <TopicManagement />
          },
          {
            path: 'category-management',
            element: <CategoryManagement />
          },
          {
            path: 'private-content',
            element: <PrivateContent />
          },
          {
            path: 'analytics',
            element: <AnalyticsManagement />
          },
          {
            path: 'workflow',
            element: <Workflow />
          },
          {
            path: 'deal-types',
            element: <DealTypeManagement />
          },
          {
            path: 'products',
            element: <ProductManagement />
          }
        ]
      }
    ]
  }
];

export default routes;

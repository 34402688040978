// Document upload
export const UPLOAD_DOCUMENT_TYPE_MAJOR = 'Major';
export const UPLOAD_DOCUMENT_TYPE_MINOR = 'Minor';
export const LIMIT_UPLOADED_FILES = 10;
export const LIMIT_FILE_SIZE = 10485760;
export const LIMIT_FILE_SIZE_LABEL = '10Mb';
export const DEAL_ID_NOT_CORRECT = 'Deal ID is not correct';
export const DEAL_DEFAULT_VERSION = 'American unamended';
export const DEAL_VERSIONS = [DEAL_DEFAULT_VERSION, 'American amended', 'European', 'Other'];
export const MAX_PORTFOLIOS_NUMBER = 100;
export const INIT_PAGE = 1;
